<div class="template__signUp">
  <div class="bgSign">
    <div class="signupOuterBox gradientTheme hasScrollbarLight">
      <div class="signupOuterContent">
        <div class="otpVerification">
          <div class="signupInnerBox otp">
            <img
              [src]="logoPath"
              alt="gatho-logo"
              class="gathoLogo"
              title="Gatho"
            />
            <h4 class="is-light titleSignUp text-center">
              Let's verify your account
            </h4>

            <div *ngIf="emailorPhoneValid" class="resendtxt">
              <p class="is-light text-center">
                We've just sent the verification code to your registered
                <span *ngIf="emailOrSMS === 'email'"> email address. </span>

                <span *ngIf="emailOrSMS === 'sms'"> mobile number. </span>
              </p>
            </div>

            <form #f="ngForm" (ngSubmit)="onSubmit(f)" *ngIf="!verifying">
              <p class="is-light text-center" *ngIf="errorMessage">
                {{ errorMessage }}
              </p>
              <p class="is-light text-center" *ngIf="attempts == 0">
                You used too many attempts.
              </p>

              <div *ngIf="attempts != 0">
                <div *ngIf="emailOrSMS === 'email'" class="emailOrSMSCheck">
                  <div *ngIf="!emailorPhoneValid" class="fieldEmailOrMobile">
                    <p class="is-light text-center helperText">
                      You have not yet completed account verification. Please
                      enter your registered email address and verification code
                      to verify your account.
                    </p>
                    <mat-form-field appearance="fill">
                      <mat-label>Email</mat-label>
                      <input
                        matInput
                        type="email"
                        name="email"
                        autocomplete="email"
                        placeholder="Email"
                        [(ngModel)]="email"
                        [formControl]="emailFormControl"
                        required
                        email
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div *ngIf="emailOrSMS === 'sms'" class="emailOrSMSCheck">
                  <div *ngIf="!emailorPhoneValid" class="fieldEmailOrMobile">
                    <p class="is-light text-center helperText">
                      You have not yet completed account verification. Please
                      enter your registered mobile number and verification code
                      to verify your account.
                    </p>
                    <mat-form-field appearance="fill">
                      <mat-label>Mobile Number</mat-label>
                      <ngx-mat-intl-tel-input
                        [preferredCountries]="['au', 'nz', 'us']"
                        [enablePlaceholder]="true"
                        placeholder="+61400000000"
                        name="mobile"
                        describedBy="phoneInput"
                        [(ngModel)]="mobile"
                        [required]="true"
                        [formControl]="mobileformControl"
                      >
                      </ngx-mat-intl-tel-input>
                    </mat-form-field>
                  </div>
                </div>

                <div class="otpInnerBox">
                  <input
                    appMoveNextMaxLength
                    [formControl]="otp1f"
                    [(ngModel)]="otp1"
                    name="otp1"
                    maxlength="1"
                    type="text"
                    inputmode="numeric"
                    pattern="\d*"
                    class="fieldOtp"
                    title="otp1"
                    required
                    autofocus
                    tabindex="1"
                    (paste)="onPaste($event)"
                    #inputOtp1
                  />
                  <input
                    appMoveNextMaxLength
                    [formControl]="otp2f"
                    [(ngModel)]="otp2"
                    name="otp2"
                    maxlength="1"
                    type="text"
                    inputmode="numeric"
                    pattern="\d*"
                    class="fieldOtp"
                    title="otp2"
                    required
                    tabindex="2"
                    (paste)="onPaste($event)"
                  />
                  <input
                    appMoveNextMaxLength
                    [formControl]="otp3f"
                    [(ngModel)]="otp3"
                    name="otp3"
                    maxlength="1"
                    type="text"
                    inputmode="numeric"
                    pattern="\d*"
                    class="fieldOtp"
                    title="otp3"
                    required
                    tabindex="3"
                    (paste)="onPaste($event)"
                  />
                  <input
                    appMoveNextMaxLength
                    [formControl]="otp4f"
                    [(ngModel)]="otp4"
                    name="otp4"
                    maxlength="1"
                    type="text"
                    inputmode="numeric"
                    pattern="\d*"
                    class="fieldOtp"
                    title="otp4"
                    required
                    tabindex="4"
                    (paste)="onPaste($event)"
                  />
                </div>

                <div class="nextbtn">
                  <button
                    mat-stroked-button
                    type="submit"
                    [disabled]="!f.valid"
                    tabindex="5"
                    #btnNext
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </form>
            <div class="resendtxt" *ngIf="!verifying">
              <p class="is-light text-center">
                {{ attempts }} attempts left!
                <a (click)="openResendCode()">Resend code?</a>
              </p>
            </div>
            <div class="verifying" *ngIf="verifying">
              <div class="center">
                <mat-spinner></mat-spinner>
                <p class="is-light text-center">
                  Verifying your account.
                  This will take a few moments, please wait...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
