import { Injectable, ElementRef } from '@angular/core';
import { ApiService } from '@gatho/services';
import { BrandingSettings } from '@shared/models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

  public branding$ = new Subject<BrandingSettings>();

  public cached?: BrandingSettings;

  constructor(
    private apiService: ApiService
  ) {
    this.loadFromStorage();
    this.cacheBrandingSettings();
    this.setGlobalBranding();
  }

  async cacheBrandingSettings(): Promise<void> {
    const data = await this.apiService.getBrandingSettings();
    localStorage.setItem('branding', JSON.stringify(data));
    this.cached = data;
  }

  loadFromStorage(): void {
    const data = localStorage.getItem('branding');
    if (data) {
      this.cached = JSON.parse(data);
    }
  }

  getBrandingSetting(key: string): string | null {
    return this.cached?.[key] ?? null;
  }

  setCssProperty(elementRef: ElementRef, name: string, setting: string, custom?: boolean): void {
    // Get value from branding settings cache
    if (!custom) {
      const value = this.getBrandingSetting(setting);
      if (value) {
        elementRef.nativeElement.style.setProperty(name, value);
      }
    }
    // Custom values get used verbatim
    else {
      elementRef.nativeElement.style.setProperty(name, setting);
    }
  }

  setGlobalBranding(): void {
    const faviconEl = document.querySelector('link[rel="icon"]');
    const faviconSetting = this.getBrandingSetting('favicon');
    if (faviconEl && faviconSetting) {
      faviconEl.setAttribute('href', faviconSetting);
    }
    const appIconEl = document.querySelector('link[rel="apple-touch-icon"]');
    const appIconSetting = this.getBrandingSetting('appIcon');
    if (appIconEl && appIconSetting) {
      appIconEl.setAttribute('href', appIconSetting);
    }
    const metaDescriptionEl = document.querySelector('meta[name="description"]');
    const metaDescriptionSetting = this.getBrandingSetting('metaDescription');
    if (metaDescriptionEl && metaDescriptionSetting) {
      metaDescriptionEl.setAttribute('content', metaDescriptionSetting);
    }
  }

}
