import { Component, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { enAU } from 'date-fns/locale';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroupDirective, NgForm, Validators, ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TermsComponent } from '../../disclaimers/terms/terms.component';
import { PrivacyPolicyComponent } from '../../disclaimers/privacy-policy/privacy-policy.component';
import { ReturnsPolicyComponent } from '../../disclaimers/returns-policy/returns-policy.component';
import { ContributorRulesComponent } from '../../disclaimers/contributor-rules/contributor-rules.component';
import { ForgotPasswordComponent } from '../login/forgot-password/forgot-password.component';
import { UserRegisterDto } from '@gatho/store/models/userRegisterDto';
import { ApiService } from '@gatho/services/api/api.service';
import { SettingsService } from '@gatho/services/api/settings.service';
import { SiteConfigDTO } from '@shared/models/SiteConfigDTO';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ScreenSizeService } from '@gatho/features/lobby/services/screen-size.service';
import { Subscription } from 'rxjs';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { BrandingService } from '@gatho/services/branding/BrandingService';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnDestroy, AfterViewInit {
  minDate: Date;
  maxDate: Date;
  public loading = false;
  public errorMessage?: string;
  public profilePicPath: string | null;
  public payload: UserRegisterDto = {
    email: '',
    password: '',
    profilePicture: '',
    displayName: '',
    fullName: '',
    dob: '',
    mobile: '',
    marketingOptIn: false,
    country: '',
    facebook: '',
    instagram: '',
    twitter: '',
    tiktok: '',
    youtube: ''
  };

  public emailValidationErrorMessage: string =
    'Please enter a valid email address';

  public settings: SiteConfigDTO = {} as SiteConfigDTO;
  private settingsSub?: Subscription;
  isMobile!: boolean;

  public backgroundImage?: string;

  public newFile: File | undefined;
  public base64image: string = '';
  public fileUploadErrorMessage?: string;

  public mylocalStorage: typeof localStorage;
  protected routeQueryParams?: Subscription;

  public termsCheckbox: boolean = false;
  public privacyCheckbox: boolean = false;

  public selectedCountry: string = '';

  @ViewChild(NgxMatIntlTelInputComponent) phoneInput:
    | NgxMatIntlTelInputComponent
    | undefined;

  constructor(
    private _router: Router,
    private _api: ApiService,
    private _settings: SettingsService,
    private _matDialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private _screenSizeService: ScreenSizeService,
    private _adapter: DateAdapter<any>,
    private _route: ActivatedRoute,
    private _branding: BrandingService
  ) {
    // Retrieve settings from store
    this.getSettings();
    // Handle modals that are opened via query in URL
    this.handleModals();
    // Set the minimum to January 1st 100 years in the past and 18 years in the past.
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDay();

    this.minDate = new Date(currentYear - 120, 0, 1);
    this.maxDate = new Date(currentYear - 18, currentMonth, currentDay);
    _screenSizeService.screenSizeUpdated$.subscribe(
      (isMobile) => (this.isMobile = isMobile)
    );
    this._adapter.setLocale(enAU);
    this.profilePicPath = '';
    this.mylocalStorage = localStorage;
  }

  ngOnDestroy(): void {
    this.settingsSub?.unsubscribe();
    this.routeQueryParams?.unsubscribe();
  }

  /* Get the application settings. */
  async getSettings() {
    // Get background from branding settings with a fallback
    this.backgroundImage = this._branding.getBrandingSetting('loginBackground') ?? "../../../../assets/images/opener-bg.jpg";
    // Retrieve settings from backend
    try {
      this.settingsSub = this._settings.settings$.subscribe((data) => {
        this.settings = data;
      });
    } catch (err) {
      console.log('err', err);
    }
  }

  handleModals() {
    this.routeQueryParams = this._route.queryParams.subscribe((params) => {
      if (params['privacy']) {
        this.openPrivacy();
      } else if (params['terms']) {
        this.openTerms();
      } else if (params['returns']) {
        this.openReturns();
      } else if (params['rules']) {
        this.openRules();
      }
    });
  }

  handleCountryChange(country?: unknown | any ) {
    if (country) {
      if (country.iso2) {
        this.payload.country = country.iso2;
        if (country.iso2 === 'us') {
          this.selectedCountry = country.iso2;
        }
      }
    }
  }

  openGetPassword() {
    const dialogConfig = {
      maxWidth: '44rem',
      width: '44rem',
      panelClass: ['gathoDialog'],
    };
    this._matDialog.open(ForgotPasswordComponent, dialogConfig);
  }

  navigateToLogin() {
    this._router.navigate(['login']);
  }

  formatErrorMessage(error: ValidationErrors | null, defaut: string) {
    let output = defaut;
    if (error) {
      if (typeof error === 'object') {
        if (error['minlength']) {
          output =
            'This field must be at least ' +
            error['minlength'].requiredLength +
            ' char long.';
        }
        if (error['message']) {
          output = error['message'];
        }
      }
    }

    return output;
  }

  ngAfterViewInit() {
    if (this.phoneInput && this.phoneInput.matMenu) {
      this.phoneInput.matMenu.panelClass = 'custom-panel';
    }
  }

  matcher = new MyErrorStateMatcher();
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  //[formControl]="fullName"
  fullName = new FormControl('', [
    Validators.required,
    Validators.minLength(4),
  ]);

  displayName = new FormControl('', [
    Validators.required,
    Validators.minLength(4),
  ]);

  dob = new FormControl('', [Validators.required]);
  termsCheck = new FormControl('', [Validators.required]);
  privacyCheck = new FormControl('', [Validators.required]);

  // @todo expand this to aussi
  mobile = new FormControl('', [Validators.required, Validators.minLength(10)]);

  facebook = new FormControl('');
  instagram = new FormControl('');
  twitter = new FormControl('');
  tiktok = new FormControl('');
  youtube = new FormControl('');

  console = console;

  upload = async (event: Event) => {
    this.fileUploadErrorMessage = '';
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.newFile = files[0];

    switch (this.newFile.type) {
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/gif':
        const base64 = await this.convertBase64(this.newFile);
        // @ts-expect-error
        this.profilePicPath = base64;
        // @ts-expect-error
        this.payload.profilePicture = base64;
        this.mylocalStorage.setItem(
          'base_64_image',
          // @ts-expect-error
          base64.split('base64,')[1]
        );
        this.mylocalStorage.setItem('file_name', this.newFile.name);
        break;
      default:
        this.fileUploadErrorMessage = 'Only Image type files are accepted.';
        // Put back to default.
        break;
    }
  };

  convertBase64 = async (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };




  // @todo finish up the submit handler.
  onSubmit(f: NgForm): void {
    //console.log(this);
    // temp check if in the US ot Canada
    // console.log(this);
    //this.payload.mobile

    const badWordsStrings = this.settings.bad_words
      .split(',')
      .map((element) => element.trim());
    const fullName = this.payload.fullName.toLowerCase();
    const displayName = this.payload.displayName.toLowerCase();

    for (const x in badWordsStrings) {
      const word = badWordsStrings[x].toLowerCase();
      if (fullName.indexOf(word) !== -1) {
        alert('Sorry you cant use ' + fullName + ' ');
        return;
      }

      if (displayName.indexOf(word) !== -1) {
        alert('Sorry you cant use ' + displayName + ' ');
        return;
      }
    }

    let submitToApi = true;

    if (!this.payload.displayName) {
      submitToApi = false;
    }
    if (!this.payload.dob) {
      this.dob.markAsTouched();
      this.dob.setErrors({ invalid: true });
      submitToApi = false;
    }
    if (!this.payload.fullName) {
      submitToApi = false;
    }

    if (!this.payload.mobile) {
      submitToApi = false;
      this.mobile.markAsTouched();
      this.mobile.setErrors({ invalid: true });
    }

    if (!this.payload.password) {
      submitToApi = false;
    }

    if (!this.termsCheckbox) {
      submitToApi = false;
      //this.termsCheck.
      this.termsCheck.markAsTouched();
      this.termsCheck.setErrors({ invalid: true });
    }

    if (!this.privacyCheckbox) {
      submitToApi = false;
      //this.termsCheck.
      this.privacyCheck.markAsTouched();
      this.privacyCheck.setErrors({ invalid: true });
    }



    if (f.valid && submitToApi) {
      this.loading = true;
      this.errorMessage = '';

      this._api
        .register(this.payload)
        .then((data: any) => {
          if (data.message) {
            if (data.message === 'error') {
              this.loading = false;
              this.errorMessage = 'Registration failed, please try again.';

              const errors = data.payload;
              for (const x in errors) {
                const errorItem = errors[x];
                if (errorItem[0] === 'mail') {
                  this.emailValidationErrorMessage = errorItem[1];
                  f.form.controls['email'].setErrors({ invalid: true });
                  f.form.controls['email'].markAsTouched();
                }

                if (errorItem[0] === 'field_phone') {
                  //errorItem[1]
                  this.mobile.markAsTouched();
                  this.mobile.setErrors({
                    invalid: true,
                    message: errorItem[1],
                  });
                }
              }
              return;
            }

            if (data.message === 'Registration Successful') {
              // good to go.
              const userINFO = {
                country: this.payload.country,
              }
              this.mylocalStorage.setItem('user_info', JSON.stringify(userINFO));
              this.loading = false;
              this._router.navigate(['sign-up/ota'], {
                state: { user: data, payload: this.payload },
              });
            }
          }
        })
        .catch((e: Error) => {
          //console.log('error', e);
          this.loading = false;
          this.errorMessage = 'Registration failed, please try again.';
        });
    }
  }

  openTerms(): void {
    const bottomSheetConfig = {
      height: 'calc(100% - 80px)',
      panelClass: ['disclaimer'],
      data: this.settings.terms,
    };
    this._bottomSheet.open(TermsComponent, bottomSheetConfig);
  }

  openPrivacy(): void {
    const bottomSheetConfig = {
      height: 'calc(100% - 80px)',
      panelClass: ['disclaimer'],
      data: this.settings.privacy,
    };
    this._bottomSheet.open(PrivacyPolicyComponent, bottomSheetConfig);
  }

  openRules(): void {
    const bottomSheetConfig = {
      height: 'calc(100% - 80px)',
      panelClass: ['disclaimer'],
      data: this.settings.rules_for_contrib,
    };
    this._bottomSheet.open(ContributorRulesComponent, bottomSheetConfig);
  }

  openReturns(): void {
    const bottomSheetConfig = {
      height: 'calc(100% - 80px)',
      panelClass: ['disclaimer'],
      data: this.settings.returns,
    };
    this._bottomSheet.open(ReturnsPolicyComponent, bottomSheetConfig);
  }
}
