import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ApiService } from '../../../../services/api/api.service';
import { UserRegisterDto } from '../../../../store/models/userRegisterDto';
import { SnackBarService } from '@gatho/services/snackbar/snackbar.service';
import {SiteConfigDTO} from "@shared/models/SiteConfigDTO";
import { ResendOtaComponent } from "../resend-ota/resend-ota.component"
import { MatDialog } from '@angular/material/dialog';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-ota-mobile',
  templateUrl: './ota-mobile.component.html',
  styleUrls: ['./ota-mobile.component.scss'],
})
export class OtaMobileComponent implements OnInit {
  public logoPath: string = 'assets/images/logos/gatho-logo-white.svg';
  // Reference to the dom element
  @ViewChild('inputOtp1') inputElement!: ElementRef;
  @ViewChild('btnNext') buttonElement!: HTMLButtonElement;
  public errorMessage?: string;
  public settings: SiteConfigDTO = {} as SiteConfigDTO;

  public otp1: number | null;
  public otp2: number | null;
  public otp3: number | null;
  public otp4: number | null;
  public attempts: number;
  public mylocalStorage: typeof localStorage;
  public email: string;
  public mobile: string = '';
  public emailorPhoneValid: boolean;
  public emailOrSMS: string = 'sms';

  public verifying = false;

  public payload: UserRegisterDto = {
    email: '',
    password: '',
    profilePicture: '',
    displayName: '',
    fullName: '',
    dob: '',
    mobile: '',
    marketingOptIn: false,
    country: '',
  };

  public pastedOtaCode: string = '';

  public forceEmailValidation: boolean = false;

  constructor(
    private _router: Router,
    private _api: ApiService,
    private _matDialog: MatDialog,
    private _snackBar: SnackBarService
  ) {
    this.otp1 = null;
    this.otp2 = null;
    this.otp3 = null;
    this.otp4 = null;
    this.email = '';
    this.attempts = 3;
    this.emailorPhoneValid = false;
    this.mylocalStorage = localStorage;

    if (
      this._router.getCurrentNavigation() &&
      this._router.getCurrentNavigation()?.extras
    ) {
      let extras = this._router.getCurrentNavigation()?.extras;

      // Just Signed up so keep the data.
      if (extras && extras.state && extras.state['payload'] && extras.state['user']) {
        this.email = extras.state['payload'].email;
        this.payload = extras.state['payload'];
        this.mobile = extras.state['payload'].mobile;
        this.emailorPhoneValid = true;
        if (this.payload.country) {
          if (this.payload.country === 'us') {
            this.forceEmailValidation = true;
          }
        }
      }
      else {
        // check for local storage.
        const userdata = this.mylocalStorage.getItem('user_info');
        if (userdata) {
          const userdataDecoded = JSON.parse(userdata);
          if (userdataDecoded && userdataDecoded.country) {
            if (userdataDecoded.country === 'us') {
              this.forceEmailValidation = true;
            }
          }
        }
      }
    }
    if (this.forceEmailValidation == true) {
      this.emailOrSMS = 'email';
    }
  }
  ngOnInit(): void {
    this.getSettings();
  }

  /* Get the application settings. */
  async getSettings() {
    try {
      // this object is cached.
      const settingsData: SiteConfigDTO = await this._api.getAppSettings();
      this.settings = settingsData;
      if (this.settings.otp_send_via_email) {
        this.emailOrSMS = 'email';
      }
    } catch (err) {
      console.log('err', err);
    }

    if (this.forceEmailValidation == true) {
      this.emailOrSMS = 'email';
    }

  }



  //[formControl]="otp1"
  otp1f = new FormControl('', [Validators.required]);
  otp2f = new FormControl('', [Validators.required]);
  otp3f = new FormControl('', [Validators.required]);
  otp4f = new FormControl('', [Validators.required]);

  matcher = new MyErrorStateMatcher();
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  mobileformControl = new FormControl('', [
    Validators.required,
  ]);

  onPaste(event: any) {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData('text');

      //console.log(pastedText);
      const arrOfDigits = Array.from(String(pastedText.trim()), Number);
      // here if arrOfDigits.length == 4
      if (arrOfDigits.length == 4) {
        this.pastedOtaCode = pastedText;
        this.otp1 = arrOfDigits[0];
        this.otp2 = arrOfDigits[1];
        this.otp3 = arrOfDigits[2];
        this.otp4 = arrOfDigits[3];
        this.buttonElement.focus();
      }
    }
  }

  onSubmit(f: NgForm): void {
    this.verifying = true;
    this.errorMessage = '';
    let ota_code = '';
    if (this.pastedOtaCode) {
      if (this.pastedOtaCode.length == 4) {
        ota_code = this.pastedOtaCode;
      }
    }

    if (!ota_code && this.otp1 && this.otp2 && this.otp3 && this.otp4) {
      ota_code = String(this.otp1 + this.otp2 + this.otp3 + this.otp4);
    }

    let fieldValueToSend = '';
    if (this.emailOrSMS === 'sms') {
      fieldValueToSend = this.mobile;
    }
    else {
      fieldValueToSend = this.email;
    }

    if (!ota_code || !fieldValueToSend) {
      this.errorMessage = 'All fields are required.';
      return;
    }
    else {
      this.attempts = this.attempts - 1;
    }

    if (ota_code) {
      // here check
      if (this.attempts != 0 && fieldValueToSend) {

        try {
          this._api
            .otaSmsCodeCheck(fieldValueToSend, ota_code)
            .then((response) => {
              if (response.status === true) {
                this._snackBar.open('Verfication Successful. You can now login to your new account.', {duration: 10000});
                this._router.navigate(['login']);
                // @TODO: refactor profile picture upload to be done during the registration process
                /*if (
                  this.payload &&
                  this.payload.password &&
                  this.payload.email
                ) {
                  this._api
                    .login(this.payload.email, this.payload.password)
                    .then((user: User) => {
                      if (this.payload.profilePicture) {
                        user.profilePicture = this.payload.profilePicture;
                        user.profilePictureSmall  = this.payload.profilePicture;
                      }
                      user.otpVerified = true;
                      this._userService.save(user);
                       if (user['accessToken']) {
                         const base_64_image = this.mylocalStorage.getItem('base_64_image');
                         const file_name =  this.mylocalStorage.getItem('file_name');
                         if (base_64_image && file_name) {
                            this._api.updateUserProfilePicture(user['accessToken'], base_64_image, file_name);
                         }
                       }
                      this._router.navigate(['']);
                    })
                    .catch((e: Error) => {
                      //console.log('vvv', e);
                      this.verifying = false;
                      this.errorMessage = "Incorrect details, please try again";
                    });
                } else {
                  this._router.navigate(['login']);
                }*/
              }
            })
            .catch((e: Error) => {
              this.verifying = false;
              console.log(e);
              this.errorMessage =
                'The details you provided are incorrect you have ' +
                this.attempts +
                ' attempts left';
              // Empty the OTP fields and focus on first OTP field
              this.otp1 = null;
              this.otp2 = null;
              this.otp3 = null;
              this.otp4 = null;
              this.inputElement.nativeElement.focus();
            });
        } catch (e) {
          this.errorMessage =
            'The details you provided are incorrect you have ' +
            this.attempts +
            ' attempts left';
        }
      }
    }
    return;
  }


  openResendCode() {
    const dialogConfig = {
      maxWidth: '44rem',
      width: '44rem',
      panelClass: ['gathoDialog'],
      data: this.settings,
    };
    this._matDialog.open(ResendOtaComponent, dialogConfig);
  }
}
