<div class="template__login">
  <div class="bgSign" [style.background-image]="'url(' + backgroundPath + ')'">
<!--    render the form and logo with the same 'ngIf' to minimise layout shift-->
    <form *ngIf="renderLogo" name="loginForm" #f="ngForm" (ngSubmit)="onSubmit(f)">
      <div class="loginInnerBox">
        <div class="gathoLogo">
          <img [src]="logoPath" alt="gatho-logo" title="Gatho"/>
        </div>
        <div *ngIf="passwordNeedsReset">
          <a variant="info" (click)="openGetPassword()" class="is-light">
            Your password needs to be reset. Reset it Here.</a
          >
        </div>

        <ng-container *ngIf="!loading; else spinner">
          <div class="mat-error" *ngIf="errorMessage">{{ errorMessage }}</div>
          <mat-form-field class="has-bgDark field-email" appearance="fill">
            <mat-label>EMAIL</mat-label>
            <input
              matInput
              type="email"
              name="email"
              autocomplete="email"
              placeholder="EMAIL"
              [ngModel]="payload.email"
              #email="ngModel"
              (blur)="checkEmailIsFromImportedUser(f)"
              required
              email
              tabindex="1"
              autofocus
            />
            <mat-error *ngIf="email.touched && email.invalid" class="mat-error"
              >Please enter your Email Address</mat-error
            >
          </mat-form-field>
          <mat-form-field class="has-bgDark field-password" appearance="fill">
            <mat-label>PASSWORD</mat-label>
            <input
              matInput
              [type]="hide ? 'password' : 'text'"
              name="password"
              autocomplete="password"
              placeholder="PASSWORD"
              [ngModel]="payload.password"
              #password="ngModel"
              required
              tabindex="2"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
              type="button"
            >
              <mat-icon [title]="hide ? 'Show password' : 'Hide password'">{{
                hide ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
          </mat-form-field>
          <button type="submit" class="is-primary" mat-flat-button>
            SIGN IN
          </button>
        </ng-container>
        <ng-template #spinner>
          <div class="center">
            <mat-spinner></mat-spinner>
            <p class="text-center">Loading...</p>
          </div>
        </ng-template>
        <div class="forgotLink">
          <a variant="info" (click)="openGetPassword()" class="is-light"
            >Forgot your password?</a
          >
        </div>
        <div class="btnSignup">
          Don't have an account?
          <a (click)="navigateToSignUp()" class="is-light">SIGN UP</a>
        </div>

        <div class="socialsLogin">
          <a [href]="platformLoginUrl('google')" *ngIf="settings.google_auth">
            <button class="gsi-material-button">
              <div class="gsi-material-button-state"></div>
              <div class="gsi-material-button-content-wrapper">
                <div class="gsi-material-button-icon">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                    <path fill="none" d="M0 0h48v48H0z"></path>
                  </svg>
                </div>
                <span class="gsi-material-button-contents">Sign in with Google</span>
                <span style="display: none;">Sign in with Google</span>
              </div>
            </button>
          </a>

          <a [href]="platformLoginUrl('facebook')" *ngIf="settings.facebook_auth">
            <img src="./assets/images/facebook-login-wht.png" />
          </a>

          <a [href]="platformLoginUrl('apple')" *ngIf="settings.apple_auth">
            <img src="https://docs-assets.developer.apple.com/published/99ff45bbe850427a9a5ff563d0e09864/apple-account-sign-up-with~dark@2x.png" />
          </a>

          <a [href]="platformLoginUrl('microsoft')" *ngIf="settings.microsoft_auth">
            <img src="https://learn.microsoft.com/en-us/entra/identity-platform/media/howto-add-branding-in-apps/ms-symbollockup_signin_light.svg" />
          </a>
        </div>
      </div>
    </form>
  </div>
</div>
