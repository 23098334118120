import { Component, OnInit, ElementRef } from '@angular/core';
import { BrandingService } from '@gatho/services/branding/BrandingService';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  public logoPath: string = 'assets/images/logos/gatho-logo-white.svg';
  public errorMessage: string = "Oops, the page you're looking for isn't here!";

  constructor(
    private brandingService: BrandingService,
    private hostElementRef: ElementRef
  ) {
    this.setBranding();
  }

  ngOnInit(): void {}

  setBranding() {
    this.logoPath = this.brandingService.getBrandingSetting('siteLogo') || this.logoPath;
    this.errorMessage = this.brandingService.getBrandingSetting('errorPageMessage') || this.errorMessage;
    this.brandingService.setCssProperty(this.hostElementRef, '--error-bg-gradient-start', 'errorBgStartColor');
    this.brandingService.setCssProperty(this.hostElementRef, '--error-bg-gradient-end', 'errorBgEndColor');
  }
}
