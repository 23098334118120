import { NgModule, Injectable } from '@angular/core';
import { RouterModule, Routes, Resolve } from '@angular/router';
import { LoginComponent } from './components/pages/login/login.component';
import { LogoutComponent } from './components/pages/logout/logout.component';
import { RouteListComponent } from './route-list/route-list.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
// import { VerifyAgeComponent } from './components/pages/verify-age/verify-age.component';   //GATHO-495
import { AuthGuardService as AuthGuard } from './services/guards/auth-guard.service';
import { ExternalAuthGuardService } from './services/guards/external-auth-guard.service';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { OtaMobileComponent } from './components/pages/sign-up/ota-mobile/ota-mobile.component';
import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';
import { DisclaimerPagesComponent } from './components/disclaimers/pages/disclaimer-pages/disclaimer-pages.component';
import { BecomeGathoVenueComponent } from "@gatho/components/pages/become-gatho-venue/become-gatho-venue.component";
import { MainLandingComponent } from "@gatho/components/pages/main-landing/main-landing.component";
import { BrandingService } from '@gatho/services/branding/BrandingService';

@Injectable({ providedIn: 'root' })
class AppTitleResolver implements Resolve<string> {
  constructor(private branding: BrandingService) {}

  resolve()
  {
    const siteName = this.branding.getBrandingSetting('siteName');
    return siteName ?? 'Gatho - See you there, from anywhere';
  }
}

const routes: Routes = [
  {
    title: AppTitleResolver,
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/lobby/lobby.module').then((m) => m.LobbyModule),
  },
  // { path: 'verify-age', component: VerifyAgeComponent },  //GATHO-495
  { path: 'gatho', title: AppTitleResolver, component: MainLandingComponent },
  { path: 'login', title: AppTitleResolver, canActivate: [ ExternalAuthGuardService ], component: LoginComponent },
  { path: 'sign-up', title: AppTitleResolver, component: SignUpComponent },
  { path: 'password-reset', title: AppTitleResolver, component: ResetPasswordComponent },
  { path: 'sign-up/ota', title: AppTitleResolver, component: OtaMobileComponent },
  { path: 'logout', title: AppTitleResolver, component: LogoutComponent },
  { path: 'tc', title: AppTitleResolver, component: DisclaimerPagesComponent },
  { path: 'privacy', title: AppTitleResolver, component: DisclaimerPagesComponent },
  { path: 'returns', title: AppTitleResolver, component: DisclaimerPagesComponent },
  { path: 'rules', title: AppTitleResolver, component: DisclaimerPagesComponent },
  { path: 'route-list', title: AppTitleResolver, component: RouteListComponent },
  { path: 'become-gatho-venue', title: AppTitleResolver, component: BecomeGathoVenueComponent },
  //Wild Card Route for 404 request
  { path: '**', title: AppTitleResolver, pathMatch: 'full', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
